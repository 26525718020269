.footer {
	background:#1b1b1b;
	color: $silver;
	padding: 50px 10px 50px;
  position:relative;
	z-index: 5;
	
	@include breakpoint(fromMedium) {
		padding: 80px 30px 50px;
	}

	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: 1350px;
		margin: 0 auto;
		
		@include breakpoint(fromMedium) {
			border-top: 1px solid #333;
			padding-top: 10px;
      flex-direction: row;
			justify-content: space-between;
		}	
	}

	&__text {
    color: rgb(153, 153, 153);
    font-size: 14px;
    font-weight: 400;
    margin: 0 auto;
    padding: 0px 10px;
    max-width: 300px;

    @include breakpoint(fromMedium) {
      font-size: 16px;
      margin: initial;
      max-width: initial;
    }
	}
	
	&__link {
    font-size: 16px;
    font-weight: 400;
		display: block;
		margin:20px 10px;
    color:#999;
		transition: color 500ms ease;

		@include breakpoint(fromMedium) {
			display: inline;
		}
		
    &:hover {
      color: $white;
    }
	}
}
