.showreel-video { 
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden;
  margin: 0 auto;

  @include breakpoint(fromMedium) {
    padding-bottom: 40%; 
  }

  @include breakpoint(from1600) {
    padding-bottom: 26%; 
  }
} 

.showreel-video iframe, 
.showreel-video object, 
.showreel-video embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
  
} 