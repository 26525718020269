.page-controls {
  @include breakpoint(toLarge){
      position:fixed;
      bottom:0;
      left:0;
      right:0;
      background:#fff;
      border-top:1px solid #ccc;
      height:40px;
      z-index: 999;

      &.locked {
        position:relative;
      }
      a {
        display:inline-block;
        width:33%;
        padding:11px;
        text-align:center;
        border-right:1px solid #ccc;
        &:nth-child(2){
          border-right:none
        }
        .disabled {
          pointer-events : none;
          cursor:default;
        }
        svg {
          width:15px;
          height:15px;
          display:inline-block;
        }
        .label {
          display:none;
        }
      }
      .back-to-work,
      .back-to-careers {
        float:left;
      }
      .page-controls-next {
        float:right;
      }
      .page-controls-prev {
        float:left;
        width:34%;
      }
  }
	a {
		@include breakpoint(fromLarge){
			padding: 20px 22px 15px;
	        position: fixed;
	        right: 0;
	        top: 140px;
	        z-index: 100;
	        text-decoration:none;
	        color:#666;
	        font-size:20px;
	        height: 60px;
	        width:auto;
	        float:none;
	        &:hover .logo {
		        display:inline
		    }
		    &:hover .label {
		        opacity:1;
		        left:20px
		    }
		}
    }
    a,
	.label {
    font-family: 'NeoSansStd' ,Arial;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    small {
      text-transform: lowercase;
    }
		@include breakpoint(fromLarge){
		    -webkit-transition: all 500ms ease;
		    -moz-transition: all 500ms ease;
		    -ms-transition: all 500ms ease;
		    -o-transition: all 500ms ease;
		    transition: all 500ms ease;
		}
	}
	a:hover
    .label p {
    	@include breakpoint(fromLarge){
        	margin:0
    	}
    }
	.label {
		@include breakpoint(fromLarge){
	        opacity:0;
	        position:absolute;
	        top:19px;
	        left:250px;
	        padding: 5px 0 0;
	        width: 140px;
	        z-index:-2;
	        strong {
	        	color:#000
	        }
	    }
    }
    .holder {
    	@include breakpoint(fromLarge){
	        position:absolute;
	        top:0;
	        right:0;
	        background:#fff;
	        z-index:-1;
	        width:60px;
	        height:60px
	    }
    }
    .back-to-work,
    .back-to-careers {
    	@include breakpoint(fromLarge){
	        padding:17px 20px 17px 27px;
	        background:#111;
	        width:64px;
	        svg {
		        fill:#ccc;
            width:22px;
            width:22px
		    }
		}
    }

    .page-controls-next,
    .page-controls-prev {
    	@include breakpoint(fromLarge){
	    	width:64px;
	        right:0;
	        padding:0;
	    }
        &.active {
        	@include breakpoint(fromLarge){
	        	right:0;
	    		width:230px
	    	}
        }
    	svg {
    		@include breakpoint(fromLarge){
		        margin:19px;
		        height:24px;
		        width:24px;
		    }
    	}
    }
    .page-controls-next {
    	@include breakpoint(fromLarge){
	        top:200px;
	        background: #FFF
	    }
    }
    .page-controls-prev {
    	@include breakpoint(fromLarge){
	        top:261px;
	        background: #FFF;
          &.first {
            top:201px
          }
	    }
    }
    svg {
    	@include breakpoint(fromLarge){
	        float:right
	    }
    }
}
