
.clear { clear:both }
.clearfix:after,
.clearfix:before {
    content:"";
    display:table
}
.clearfix:after{
    clear:both;
}

.titleDate {
    font-size: 64px;
    letter-spacing: -6px;
    line-height: 40px;
    font-family: 'NeoSansStd', sans-serif;
    margin:0 0 20px;
    height:100px;
    span{
        display:block;
        font-size:18px;
        letter-spacing:0
    }
}

