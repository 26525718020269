.approach-page {
    .footer {
      background:#1b1b1b;
      @include breakpoint(fromMedium){
        background:#111;
      }
    }
    .cta-footer {
      background: url(/build/img/mt-bg-tile-large.png) repeat #222222;
      background-size:30%;
      padding:50px 0;
      text-align: center;
      color:#fff;
      @extend .clearfix;
      @include breakpoint(fromMedium){
          padding:150px 0;
          background-size:auto;
      }
      .button {
          display:block;
          float:none;
          margin:0 auto;
          max-width: 250px;
          padding: 15px;
          border-color:$red;
          &:hover {
              background:$red;
              color:#fff;
          }
      }

    }
    .partner-grid {
        background:#eceaea;
        padding:50px 0;
        h2 {
            font-size:16px;
            margin:0 0 40px  ;
            text-align: center;
            @include breakpoint(fromLarge){
                font-size:20px;
                margin:0 0 80px ;
            }
        }
        @include breakpoint(fromLarge){
            padding:140px 0;
        }
    }
}
.approach__intro{
  background:#fff;
  @include breakpoint(fromLarge){
      margin-top: 720px;
      position: relative;
      z-index: 1;
  }
}
.approach__tabs {
    padding:70px 0;
    background:$red;
    color:#fff;
    font-size:14px;
    text-align: center;
    position: relative;
    z-index: 2;
    @include breakpoint(fromMedium){
        padding:115px 0;
        font-size:20px;
    }
    .row {
        max-width: 850px
    }
    .animate-in {
      @include breakpoint(fromMedium){
        opacity:0;
        transform:translate3d(0,-50px,0);
        transition: opacity 500ms ease, transform 500ms ease;
        &.active {
          opacity:1;
          transform:translate3d(0,0,0);
        }
      }
    }
    svg {
        fill:#660000;
        stroke-color:#660000;
        height:70px;
        width:70px;
        display: block;
        margin:0 auto;
        @include transition(fill 200ms linear);
        @include breakpoint(fromMedium){
            width:80px;
            height:80px;
        }
    }
    a {
        color:#fff;
    }
    span {
        display:block;
        margin:20px 0 0;
        font-family: 'NeoSansStd', Arial;
    }
    .column {
        position: relative;
    }

    .insight {
      .animate-in {
        transition-delay:250ms;
      }
    }
    .creativity {
      .animate-in {
        transition-delay:500ms;
      }
    }
    .tech {
      .animate-in {
        transition-delay:750ms;
      }
    }
    .plus {
        position: absolute;
        top:0;
        right:0;
        font-size:35px;
        color:#660000;
    }
    .bubble-one {
        fill:#b81919;
        -webkit-animation: beaker-bubble-one 3s infinite; /* Safari 4+ */
        -moz-animation:    beaker-bubble-one 5s infinite; /* Fx 5+ */
        -o-animation:      beaker-bubble-one 5s infinite; /* Opera 12+ */
        animation:         beaker-bubble-one 5s infinite; /* IE 10+, Fx 29+ */
    }
    @-webkit-keyframes beaker-bubble-one {
      0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @-moz-keyframes beaker-bubble-one {
      0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @-o-keyframes beaker-bubble-one {
      0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @keyframes beaker-bubble-one {
      0%   { transform:translate3d(0,10px,0);opacity: 0; }
      50% { transform:translate3d(0,0,0);opacity: 1; }
      100% { transform:translate3d(0,0,0);opacity: 0; }
    }
    .bubble-two {
        fill:#b81919;
        -webkit-animation: beaker-bubble-two 3s infinite; /* Safari 4+ */
        -moz-animation:    beaker-bubble-two 5s infinite; /* Fx 5+ */
        -o-animation:      beaker-bubble-two 5s infinite; /* Opera 12+ */
        animation:         beaker-bubble-two 4000ms infinite; /* IE 10+, Fx 29+ */
    }
    @-webkit-keyframes beaker-bubble-two {
      0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @-moz-keyframes beaker-bubble-two {
      0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @-o-keyframes beaker-bubble-two {
      0%   { opacity: 0; }
      100% { opacity: 1; }
    }
    @keyframes beaker-bubble-two {
      0%   { transform:translate3d(0,11px,0);opacity: 0; }
      50% { transform:translate3d(0,0,0);opacity: 1; }
      100% { transform:translate3d(0,0,0);opacity: 0; }
    }
}

.approach__design {
  background-repeat:no-repeat;
  background-position: center -40px;
  color:#fff;
  margin: 80px 0 0;
  padding: 30px 0 40px;
  @include breakpoint(fromMedium){
    margin:0;
    padding:100px 0;
  }
  @include breakpoint(fromLarge){
      padding:180px 0 110px;
  }
  .column {
    @include breakpoint(fromLarge){
      padding:20px;
      &:first-child {
        margin-right:12%;
      }
    }
  }


  .row {
    @include breakpoint(fromMedium){
      max-width: 960px
    }
  }
  h2 {
    @include content-title;
    font-size:24px;
    font-weight: 300;

    @include breakpoint(fromMedium){
      color:#fff;
      text-align: left;
      margin:0 0 20px;
      font-size:28px;
    }
  }
  p {
    @include breakpoint(fromMedium){
      line-height: 1.7;
      font-size:13px;
      margin:0 0 10px;
    }
  }

}
.approach__panels {
    background:#fff;
    padding:50px 0 0;
    @extend .clearfix;
    @include breakpoint(fromMedium){
        padding:0;
    }
    .row {
        max-width:1220px;
    }
    ul {
        color:#666;
        margin:0;
        padding:0;
        list-style-type: none;
    }
    li {
        margin:0 0 10px;
        padding:0 0 0 25px;
        font-size:16px;
        background:url('/src/svg/li-tick.svg') no-repeat left center;
    }
    p {
        font-size:18px;
        padding:0;
        margin:0 0 10px;
    }

    .approach__integrated {
      @include breakpoint(fromMedium){
        padding:50px 0;
      }
      @include breakpoint(fromLarge){
        padding:80px 0;
      }
      .accordion-toggle {
        @include content-title;
        color: $mine-shaft;
        font-size:18px;

        @include breakpoint(fromMedium){
          font-size:20px;
        }
      }
      .desc {
        @include breakpoint(fromMedium){
          padding:30px
        }
        @include breakpoint(fromLarge){
          padding:30px 0
        }
      }
    }

    .approach__trusted {
        .top {
          background:#eceaeb;
          padding:90px 0;
          .row {
              max-width:840px;
              text-align: center;
              line-height: 1.7;
              position: relative;
          }
          p {
            margin: 10px 0;
          }
          h2 {
            @include content-title;
            font-size:24px;
            @include breakpoint(fromMedium){
              font-size: 28px;
            }
          }




        }
        .mobile-accordian {
          margin:70px 0 40px;
            @include breakpoint(fromMedium){
              margin:0;
                text-align: left;
                padding:100px 0;
                h3 {
                    font-family: 'NeoSansStd', Arial;
                    font-size:24px;
                }
                .row:nth-child(2){
                    margin-top:100px;
                }
                .row {
                    max-width: 930px;
                    line-height: 1.7
                }
            }
            @include breakpoint(fromLarge){
                padding:170px 0 200px;
            }
        }
        .icon {
            display:none;
            @include breakpoint(fromMedium){
                display: block;
                float:left;
                width:15%;
                position:relative;
                svg {
                  position:absolute;
                  top:0;
                  left:0;
                  width:50px;
                  height:50px;
                  margin-top:10px;
                }
            }
            @include breakpoint(fromLarge){
                width:10%;
            }
        }
        .desc {
            @include breakpoint(fromMedium){
                float:right;
                width:80%;
                font-size:15px;
                color:#666;
            }
            @include breakpoint(fromLarge){
                width:85%;
            }
        }
    }

    .proactive-support {
        @include breakpoint(fromMedium){
            max-width: 940px;
            padding-top:50px;
            padding-bottom:50px;
        }
        @include breakpoint(fromLarge){
            padding-top:100px;
            padding-bottom:100px;
        }
        h3 {
            @include breakpoint(fromMedium){
                font-family: 'NeoSansStd', Arial;
                font-size:28px;
                color:$mine-shaft;
            }
        }
        p {
            color:#666;
            line-height: 1.7
        }
    }
    .accordion-toggle {
        border-bottom:1px solid #e4e4e4;
        padding:13px 0;
        margin:0;
        font-size:19px;
        color: #2e2e2e;
        font-family: 'NeoSansStd', Arial;
        .prefix-icon {
          float: left;
          height: 20px;
          margin-right: 15px;
          width: 20px;
          @include breakpoint(fromMedium){
            display:none
          }
        }
        &.active {
            border-bottom: none;
            @include breakpoint(fromMedium){
                margin:0;
            }
        }
        @include breakpoint(fromMedium){
            border: none;
            font-size:15px;
            .mobile {
                display:none;
            }
            .desktop {
                display:block;
            }
        }
    }
}
