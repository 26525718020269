.home {
  .hero {
    .content {
      @media (max-width: 767px) {
        top:50%;
      }
      @media (max-width: 767px) and (orientation: landscape){
        margin-top: 0;
      }
      @include breakpoint(1025px){
        min-height: auto;
      }
    }
    .video {
    }
    .bar {
      bottom:0;
    }
    .panel {
      @include breakpoint(1025px){
        margin-top:0;
      }
    }
    .slide {
      height: 100vh;
      max-height: 800px;
      
      @include breakpoint(1025px){
        max-height: none;
      }

      h2 {
        max-width: 700px;
      }
    }

  }
  .section-wrapper {
    margin-top:0;
  }
  header {
    @include breakpoint(1025px){
      &.passed-threshold {
        background-color:#111;
      }
    }

  }
}


.home__intro {
    position:relative;
    z-index:20;
    background:#fff;

    .column {
        border-bottom:1px solid #e4e4e4;
        padding:10px 0;
        @extend .clearfix;
        &.first {
            //border-top:1px solid #e4e4e4;
            @include breakpoint(fromMedium){
                //border:none;
            }
        }
        &:nth-child(4) {
          border-bottom:none;
        }
        svg {
          display:none;
        }
        @include breakpoint(fromMedium){
            border:none;
            padding:0;
            &:nth-child(3){
              clear:both;
            }
        }
        @include breakpoint(1025px){
          &:nth-child(3){
            clear:none;
          }
        }
    }
 
    .desc {
      @include breakpoint(fromMedium){
        margin:0 auto 30px;
        width:80%
      }
      @include breakpoint(fromLarge){
        margin:0 auto;
      }

    }
 
    .mobile-accordian {
      .accordion-toggle {
        &::after {
          top:6px
        }
        &.active {
          &::after {
            top: -6px;
          }
        }
      }
      .accordion-content {
        &.active {
          border-bottom:none;
        }
      }

    }
}

/* TEMP DISABLED
.home__clientgrid  {
  background:#eceaea;
  padding:50px 0;
  text-align: center;
  @include breakpoint(from569){
    padding:90px 20px;
  }
  @include breakpoint(2000px){
    padding:150px 0;
  }
  .holder {
    @extend .clearfix;
  }
  &::after {
    @include sectionArrow(#eceaea);
  }
  h2 {
    text-align: center;
    margin:20px 20px 30px;
    color:#666;
    @include breakpoint(from569){
      margin:0 0 50px;
    }
  }

  .hide-at-mobile {
    display:none;
    @include breakpoint(from569){
      display:block;
    }
  }
  .show-more {
    display:inline-block;
    @include breakpoint(from569){
      display:none;
    }
  }
}
*/

.home__facts {
    background:#94b029;
    color:#fff;
    @include breakpoint(2000px){
      padding:140px 20px;
    }
    .ie9 & {
      display:none;
    }
    .column {
      float:left;
      width:48%;
      text-align:center;
      font-size:55px;
      margin-bottom:50px;
      padding:0;
      p {
        margin:0;
        font-family: "Merriweather",sans-serif;
        font-weight: 300;
      }
      @include breakpoint(from768){
          width:25%;
          margin-bottom:0
      }
      &.dealers {
        svg {
          width:90px;
          height:90px;
          position:relative;
          top:-8px;
        }
        p {
          position:relative;
          top: -19px;
        }
      }
    }
    path {
        fill:#80991e
    }
    span {
        font-size:18px;
        display:block;
        font-family: "Merriweather Sans",sans-serif;
        font-weight: 300;
    }
    .wrapper {

        @include breakpoint(fromLarge){
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            -moz-opacity: 0;
            -khtml-opacity: 0;
            opacity: 0;
            -webkit-transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 1s ease;
            max-width:1800px;
        }

    }
    &.active .wrapper {
        @include breakpoint(fromLarge){
            max-width:1250px;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            -moz-opacity: 1;
            -khtml-opacity: 1;
            opacity: 1
        }
    };
}


.home__aboutus {
    background-repeat:no-repeat;
    background-position: -186px center;
    background-color: #111;
    text-align:center;
    color:#fff;
    padding:130px 20px 140px;
    line-height:24px;
    position:relative;
    z-index: 5;
    @include breakpoint(568px){
      background-position: top center;
    }
    @include breakpoint(1025px){
      padding:130px 20px 157px;

      background-size: auto;
    }
    .titleDate {
        margin-bottom:50px;
    }
    .wrapper {
        max-width:720px;
        margin:0 auto;
    }

}