.featured-brand-list {
    display: none;
    margin: 0 auto 20px;
    width: 100%;
    @include breakpoint(fromMedium) {
      display: block;
      margin: 40px auto;
    }

    &__container {
      display: flex;
      max-width: 1300px;
      padding: 0 25px;
      margin: 0 auto;
      list-style: none;
      align-items: center;
      
      @include breakpoint(toMedium) {
        flex-wrap: wrap;

      }
      @include breakpoint(fromMedium) {
          justify-content: space-between;
      }
    }

    &__item {
        flex-basis: 33.333%;
        padding: 15px;

        @include breakpoint(fromMedium) {
            padding: 0 30px;
            max-width: 230px;
            flex-basis: none;
        }

      &--text{
        color: $dove-grey;
        font: 16px 'NeoSansStd', arial;
        text-align: center;

        @include breakpoint(fromMedium) {
          font-size: 24px;
        }

        @include breakpoint(fromLarge) {
          font-size: 28px;
        }
      }
    }

    &__svg {
        width: 100%;
        max-height: 65px;
        fill: $dove-grey;
    }
}