
.spinner {
  background-color: #0A6CB4;
  // border: 2px solid #00AEEF;
  -webkit-border-radius: 30px;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 15px;
  top: 15px;
  -webkit-animation: pulsate 1.5s ease-out;
  -webkit-animation-iteration-count: infinite; 
    .safari & {
      display:none;
    }
}
body {
    background:#1b1b1b;
    height:100%
}
body > .spinner {
    position: fixed;
    width: 40px;
    z-index: -15;
    left:55%;
    top:50%;
    margin:-30px 0 0 -30px;
    @include breakpoint(from768){
      left:50%
    }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.5, 0.5);
    background-color: #0A6CB4;
  }
  50% {
    -webkit-transform: scale(1, 1);
    background-color: #00AEEF;
  }
  100% {
    -webkit-transform: scale(0.5, 0.5);
    background-color: #0A6CB4;

  }
}

@-webkit-keyframes rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}
@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
  }
}

.loading-tread {
  position:fixed;
  top:0;
  left:0;
  width:150%;
  z-index: -10;
  .em {
    display:none;
    fill:#222;
  }
  .tread {
    fill:#222;
    display:none;
  }

  @keyframes tread-animation {
    0%   { transform:translate3d(0,0,0) }
    50% { transform:translate3d(0,-10%,0) }
    100% { transform:translate3d(0,0,0) }
  }


  animation:         tread-animation 50s infinite;

}

