//name that color - http://chir.ag/projects/name-that-color/

$white: #fff;
$cod-grey: #1b1b1b;
$mine-shaft: #333;
$dove-grey: #696868;
$silver: #ccc;
$gallery: #eceaea;
$alabaster: #f9f9f9;
$black: #000;
$mariner: #0A6CB4;
$radical-red: #f53855;
$cerulean: #00AEEF;
