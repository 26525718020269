// Hybrid of new and old, lots of duplicate css, not good

@import "mixins/utilities/font-face";
@import "mixins/utilities/breakpoints";
@import "mixins/typography";
@import "mixins/buttons";
@import "mixins/animations";

@import "base/font-face";
@import "base/color-variables";

@import "components/pods/text-image";
@import "components/pods/text-panel";
@import "components/pods/informative-list";
@import "components/header";
@import "components/burger-menu";
@import "components/offcanvas-menu";
@import "components/clock-footer";
@import "components/featured-brand-list";
@import "components/featured-counts";
@import "components/icon-list";
@import "components/icon-list-featured";
@import "components/slider";
@import "components/more-content";
@import "components/partners";
@import "components/contact-form";
@import "components/custom-selects";

@import "layout/bands";
@import "layout/connect-logo";
@import "layout/responsive-background";
@import "layout/home";
@import "layout/page-hero";

@import "vendor/normalize";
@import "vendor/grid";


/* LEGACY */
@import "legacy/base/helper-mixins";
@import "legacy/base/helper-classes";
@import "legacy/base/base";
@import "legacy/base/loading-spinner";
@import "legacy/base/buttons";
@import "legacy/base/forms";


@import "legacy/components/slick-slider";

@import "legacy/components/contact-us";
@import "legacy/components/page-controls";
@import "legacy/components/hero-slider";
@import "legacy/components/accordians";
@import "legacy/components/footer";

@import "legacy/layout/product";
@import "legacy/layout/approach";
@import "legacy/layout/work-landing";
@import "legacy/layout/work-inner";
@import "legacy/layout/news";
@import "legacy/layout/contact";
@import "legacy/layout/404";


















/*-------------------------------
16) Page scrolling
---------------------------------*/
@media all and (min-width: 1025px) {
    .pageScrollers {
        position:fixed;
        top:280px;
        right:0;
        background:#eee;
        padding:24px 26px 20px;
        z-index: 11;
        display:none;
        height:72px;
        width:75px
    }
    .pageScrollers.scrollUpArrow {
        top:207px
    }
    .pageScrollers:hover {
        background:#000;
        -webkit-transition: background 400ms ease-in;
           -moz-transition: background 400ms ease-in;
            -ms-transition: background 400ms ease-in;
             -o-transition: background 400ms ease-in;
                transition: background 400ms ease-in
    }
    .pageScrollers:hover svg {
        fill:#fff
    }
    .pageScrollers:active {
        background:#94B029
    }
    .pageScrollerNav  {
        display:none
    }
}


/*-------------------------------
 17) Shift-Up CSS animations
---------------------------------*/
@media all and (min-width: 1025px) {
    .cssanimations.csstransitions .shiftUp {
        position:relative;
        transform:translate3d(0,500px,0);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        opacity: 0
    }
    .cssanimations.csstransitions .shiftUp.shiftedUp {
        animation: shiftUp 500ms ease forwards;
        -webkit-animation: shiftUp 500ms ease forwards;
        -moz-animation:  shiftUp 500ms ease forwards;
        -ms-animation: shiftUp 500ms ease forwards;
        -o-animation: shiftUp 500ms ease forwards;
        -webkit-transition: all 500ms ease-in;
           -moz-transition: all 500ms ease-in;
            -ms-transition: all 500ms ease-in;
             -o-transition: all 500ms ease-in;
                transition: all 500ms ease-in;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                    filter: alpha(opacity=100);
              -moz-opacity: 1;
            -khtml-opacity: 1;
                   opacity: 1
    }
    @keyframes shiftUp {
        0% { transform:translate3d(0,500px,0); }
        100% { transform:translate3d(0,0,0); }
    }
    @-moz-keyframes shiftUp {
        0% { -moz-transform:translate3d(0,500px,0); }
        100% { -moz-transform:translate3d(0,0,0); }
    }
    @-webkit-keyframes shiftUp {
        0% { -webkit-transform:translate3d(0,500px,0); }
        100% { -webkit-transform:translate3d(0,0,0); }
    }
}
