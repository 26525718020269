@mixin content-title {
  font: 26px 'NeoSansStd', arial;

  @include breakpoint(fromMedium) {
    font-size: 32px;
  }

  @include breakpoint(fromLarge) {
    font-size: 40px;
  }
}

@mixin content-body {
  font: 300 20px/1.4 'NeoSansStd', arial;
}

@mixin content-body-strong {
  font: 400 20px/1.4 'NeoSansStd', arial;
}

@mixin content-body--large {
  font: 300 20px/1.4 'NeoSansStd', arial;

  @include breakpoint(fromMedium) {
    font: 300 28px/1.4 'NeoSansStd', arial;
  }

}

@mixin content-body--large-strapline {
  font: 300 26px/1.4 'NeoSansStd', arial;

  @include breakpoint(fromMedium) {
    font: 300 28px/1.4 'NeoSansStd', arial;
  }

}

@mixin sup-title {
  font: 500 20px/1.4 'NeoSansStd', arial;
}