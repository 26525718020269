.page-404 {
  background:#222;
  .content {
    background:#222;
    min-height:500px;
    color:#fff;
    text-align:center;
    padding:50px 20px 20px;
    position:relative;
    @include breakpoint(fromLarge){
      padding:20px
    }
  }
  .message {
    @include breakpoint(fromLarge){
      position:absolute;
      max-width:500px;
      max-height:300px;
      top:50%;
      left:50%;
      margin:-250px 0 0 -250px;
    }

  }
  .section-title {
    color:#fff;
  }
  h2 {
    font-size: 60px;
    margin: 20px 0;
  }
  h3 {
    font-size:20px;
    color:#cccccc
  }
  p {
    font-size:12px;
    color:#ccc;
    margin: 10px auto 50px;
    max-width: 272px;
  }
  .button {
    padding:10px 30px;
    display:inline-block;
    width:auto;
    &:hover {
      color:#fff;
    }
  }
}
