.text-image {
  color: $mine-shaft;
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
  width: 100%;

  @include breakpoint(fromMedium) {
    margin: 14px 0;
  }

  @include breakpoint(fromLarge) {
    flex-direction: row;
    margin: 20px 0;
  }

  &--content-left {
    @include breakpoint(fromLarge) {
      flex-direction: row-reverse;
    }
  }

  .content-band--tertiary & {
    color: $white;
  }

  .content-band__container--no-margins & {
    margin: 0;
  }

  .text-image__item {
    @include breakpoint(fromLarge) {
      width: 50%;
    }

    &--flex {
      align-items: flex-start;
      display: flex;
      justify-content: center;
    }

    &--image {
      background-color: $cod-grey;
    }
  }

  .text-image__image {
    display: block;
    width: 100%;
  }

  .text-image__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 100px 50px;
    max-width: 700px;

    @include breakpoint(fromMedium) {
      padding: 100px 80px;
    }
  }

  .text-image__text {
    @include content-body;
    margin: 20px 0 10px;

    &--large {
      @include content-body--large;
    }
  }

  .text-image__title {
    @include content-title;
    margin: 0;
  }

  .text-image__button {
    @include button-primary;
    align-self: flex-start;
  }

  &.text-image__reverseMobile {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(1025px) {
      flex-direction: initial;
    }
  }
}
