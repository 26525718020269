.our-offices {
  width: 100%;
  .our-offices__container {
    display: flex;
    flex-direction: column;
    padding: 0 30px;

    @include breakpoint(fromLarge) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 800px;
      margin: 0 auto;
      justify-content: center;
    }

    .address-holder {
      display: none;
      p {
        max-width: 170px;
        font-size: 16px;
        margin: 30px auto;
        text-align: center;
      }
      &.active {
        display: block;
      }
    }
    .wrapper {
      max-width: 750px;
      padding: 50px 20px;
      @include breakpoint(2000px) {
        padding: 140px 20px;
      }
    }
    p {
      @include content-body-strong;
      display: block;
      font-size: 18px;
      margin: 0 0 10px;
      text-align: center;
      color: #ccc;
    //   @include breakpoint(1025px) {
    //     display: block;
    //     font-size: 18px;
    //     margin: 0 0 10px;
    //     text-align: center;
    //     color: #ccc;
    //   }
    }
    h4 {
      @include content-title;
      margin: 20px 0 0;
      font-size: 24px;
      text-align: center;
      color: $white;

      @include breakpoint(1025px) {
        font-size: 32px;
        float: none;
        margin: 0 0 5px;
        text-align: center;
        width: 100%;
      }
    }
  }

  .content-band__item {
    border-top: 1px solid $dove-grey;
    padding: 10px 0;

    @include breakpoint(1025px) {
      border-top: none;
    }
    &:first-child {
      border-top: none;
    }
  }

  .contact-icons {
    float: right;
    width: auto;
    margin: 0 0 0;
    @include breakpoint(1025px) {
      float: none;
      margin: 0 auto;
      width: 79px;
    }
  }
  .envelope,
  .pin,
  .phoneIcon svg {
    fill: #666;
    display: inline;
    width: 22px;
    margin: 0 6px;
    transition: fill 0.6s ease;
    @include breakpoint(450px) {
      margin: 0 16px;
    }
    @include breakpoint(1025px) {
      margin: 0 6px;
    }
  }
  .envelope:hover path,
  .pin:hover,
  .phoneIcon:hover svg {
    fill: #fff;
  }
  .envelope {
    width: 25px;
    path {
      transition: fill 0.6s ease;
      fill: #666;
    }
  }
  .phoneIcon {
    display: inline-block;
    width: 35px;
    svg {
      fill: #666;
      width: 45px;
      position: relative;
      top: 11px;
    }
    @include breakpoint(1025px) {
      display: none;
    }
  }
  .map-trigger {
    @include breakpoint(fromMedium) {
      display: inline-block;
    }
    &.active {
      svg {
        fill: #fff;
      }
    }
  }
}

.office-location-map {
  position: relative;

  &__close {
    display: none;
    padding: 20px 25px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 5;
    background: #000;
    transition: all 300ms ease;

    @include breakpoint(fromMedium) {
      display: block;
    }

    svg {
      fill: #fff;
    }

    &:hover {
      background: #333;
      transform: scale(1.05);
    }
  }

  &__map {
    @include breakpoint(1025px) {
      width: 100%;
      height: 500px;
    }
  }
}
