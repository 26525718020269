.content-band {
  position: relative;
  
  &--overflow-hidden {
    overflow: hidden;
  }
  
    &.content-band--active {
      opacity: 1;
      transform: scale(1);
    }

    &--arrow-down {
      position: relative; 

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30px 35px 0;
        position: absolute;
        bottom: -29px;
        left: 50%;
        -webkit-transition: bottom 500ms linear;
        transition: bottom 500ms linear;
        transform: translateX(-50%);
        z-index: 200;
      } 
    }
  
    @at-root .content-band--primary {
      background-color: $white;
  
      & + .content-band--primary .content-band__container { //sass-lint:disable-line force-element-nesting
        @include breakpoint(fromMedium) {
          margin-top: -14px;
          min-height: 14px;
        }
  
        @include breakpoint(fromLarge) {
          margin-top: -20px;
          min-height: 20px;
        }
  
        &.content-band__container--no-margins {
          margin: 0 auto;
        }
      }
  
      &.content-band--no-margins + .content-band--primary .content-band__container { //sass-lint:disable-line force-element-nesting
        margin: 0 auto;
      }

      &.content-band--arrow-down {
        &::after {
          border-color: $white transparent transparent;
        }
      }
    }
  
    @at-root .content-band--secondary {
      background-color: $gallery;
  
      & + .content-band--secondary .content-band__container { //sass-lint:disable-line force-element-nesting
        @include breakpoint(fromMedium) {
          margin-top: -14px;
          min-height: 14px;
        }
  
        @include breakpoint(fromLarge) {
          margin-top: -20px;
          min-height: 20px;
        }
  
  
        &.content-band__container--no-margins {
          margin: 0 auto;
        }
      }
  
      &.content-band--no-margins + .content-band--secondary .content-band__container { //sass-lint:disable-line force-element-nesting
        margin: 0 auto;
      }

      &.content-band--arrow-down {
        &::after {
          border-color: $gallery transparent transparent;
        }
      }
    }
  
    @at-root .content-band--tertiary {
      background-color: $mine-shaft;
  
  
      & + .content-band--tertiary .content-band__container { //sass-lint:disable-line force-element-nesting
        @include breakpoint(fromMedium) {
          margin-top: -14px;
        }
  
        @include breakpoint(fromLarge) {
          margin-top: -20px;
        }
  
  
        &.content-band__container--no-margins {
          margin: 0 auto;
        }
      }
 
      &.content-band--no-margins + .content-band--tertiary .content-band__container { //sass-lint:disable-line force-element-nesting
        margin: 0 auto;
      }

      &.content-band--arrow-down {
        &::after {
          border-color: $mine-shaft transparent transparent;
        }
      }
    }

    @at-root .content-band--quaternary {
      background-color: $mariner;
  
      & + .content-band--quaternary .content-band__container { //sass-lint:disable-line force-element-nesting
        @include breakpoint(fromMedium) {
          margin-top: -14px;
        }
  
        @include breakpoint(fromLarge) {
          margin-top: -20px;
        }
  
  
        &.content-band__container--no-margins {
          margin: 0 auto;
        }
      }
 
      &.content-band--no-margins + .content-band--quaternary .content-band__container { //sass-lint:disable-line force-element-nesting
        margin: 0 auto;
      }

      &.content-band--arrow-down {
        &::after {
          border-color: $mariner transparent transparent;
        }
      }
    }
    
    &.content-band--background {
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
  
      @include breakpoint(fromMedium) {
        background-position: center;
        background-size: cover;
        padding-top: 0;
  
        &::before {
          background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 70%);
          bottom: 0;
          content: '';
          left: 0;
          opacity: .8;
          position: absolute;
          right: 0;
          top: 0;
        }
  
        &.content-band--tertiary {
          &::before {
            background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 70%);
          }
        }
      }
  
      .content-band-mobile-img {
        width: 100%;
  
        @include breakpoint(fromMedium) {
          display: none;
        }
      }
    }
  
    .content-band__img {
      width: 100%;
  
      @include breakpoint(fromMedium) {
        display: none;
      }
    }

    @at-root .content-band__container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 5;

        @include breakpoint(fromMedium) {
            padding: 0 7px;
        }

        @include breakpoint(fromLarge) {
            padding: 0 10px;
        }

        @include breakpoint(fromMedium) {
            flex-direction: row;
        }
        
        &.content-band__container--row {
            @extend .row;
        }

        &.content-band__container--padding {
            padding: 10px;
        }

        &.content-band__container--no-margins {
            padding: 0;
        }
        
        &.content-band__container--parallax {
            background-attachment: fixed;
            background-position: center center;
            background-size: cover;
        }
    }
  
    &.content-band--spacing-medium {
      padding: 20px 0;
  
      @include breakpoint(fromMedium) {
        padding: 30px 7px;
      }
  
      @include breakpoint(fromLarge) {
        padding: 40px 0;
      }
  
      &.content-band--background {
        @include breakpoint(toMedium) {
          padding: 0 0 20px;
        }
      }
    }
  
    &.content-band--spacing-large {
      padding: 30px 0;
  
      @include breakpoint(fromMedium) {
        padding: 50px 7px;
      }
  
      @include breakpoint(fromLarge) {
        padding: 70px 0;
      }
  
      &.content-band--background {
        @include breakpoint(toMedium) {
          padding: 0 0 30px;
        }
      }
    }
  
    &.content-band--spacing-xlarge {
      padding: 60px 0;
  
      @include breakpoint(fromMedium) {
        padding: 100px 7px;
      }
  
      @include breakpoint(fromLarge) {
        padding: 175px 0;
      }
  
      &.content-band--background {
        @include breakpoint(toMedium) {
          padding: 0 0 40px;
        }
      }
    }
  }
