.icon-list {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 20px;
  max-width: 1200px;
  text-align: center;
  width: 100%;

  @include breakpoint(fromMedium) {
    margin: 50px auto;
  }

  &__item {
    align-items: center;
    display: flex;
    margin: 10px 0;
    width: 100%;

    @include breakpoint(fromMedium) {
        margin: 40px 0;
        width: 50%;
    }

    @include breakpoint(fromLarge) {
        margin: 40px 0;
        width: 25%;
    }
  }

  &__icon {
    margin: 0 20px;
    
    svg {
      height: 45px;
      width: 45px;
    }
  }

  &__title {
    @include content-title;
    color: $mine-shaft;
    font-size: 18px;
    text-align: left;
    
    @include breakpoint(fromMedium) {
      font-size: 18px;
    }

    .content-band--tertiary & {
      color: $white;
    }
  }
}
