.informative-list {
  color: $mine-shaft;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin: 10px auto 0;
  max-width: 650px;
  width: 100%;
  
  @include breakpoint(fromLarge) {
      flex-direction: row;
      max-width: 1200px;
   }


    &--content-left {
        @include breakpoint(fromMedium) {
            flex-direction: row-reverse;
        }
    }

  .content-band--tertiary & {
    color: $white;
  }

   .informative-list__item {
    padding: 100px 0;
    margin: 0 50px;

    @include breakpoint(fromLarge) {
        width: calc(50% - 100px);
    }

    &:last-child {
        @include breakpoint(toLarge) {
            border-top: 1px solid $silver;
        }
    }

    &--border {
        border-bottom: 1px solid $silver;
    }

  }

  .informative-list__image {
    display: block;
    width: 100%;
  }

  .informative-list__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 50px;
    max-width: 600px;
  }

  .informative-list__text {
    @include content-body;
    margin: 20px 0 10px;

    &--large {
        @include content-body--large;
    }
  }

  .informative-list__strong {
      @include content-body-strong;
  }

  .informative-list__title {
    @include content-title;
    margin: 0;
  }

  .informative-list__button {
    @include button-primary;
    align-self: flex-start;
  }
}
