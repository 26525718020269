.featured-counts {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  font: 14px 'NeoSansStd', arial;
  margin: 30px auto;
  max-width: 900px;
  padding: 0 10px;
  text-align: center;
  width: 100%;

  @include breakpoint(fromMedium){
    font-size: 18px;
    margin: 80px auto;
  }

  @include breakpoint(fromLarge){
    font-size: 22px;
  }

  &__item {
    margin: 30px 0;
    width: 50%;

    @include breakpoint(fromMedium){
      width: 25%;
    }
  }

  &__title {
    font-size: 32px;

    @include breakpoint(fromMedium){
      font-size: 40px;
    }

    @include breakpoint(fromLarge){
      font-size: 52px;
    }
  }

  &__subtitle {
    font-size: 18px;

    @include breakpoint(fromMedium){
      font-size: 22px;
    }
  }

  &__plus {
    font-size: 18px;
    position: absolute;
    

    @include breakpoint(fromMedium){
      font-size: 28px;
      padding: 0 10px;
    }
  }
}