
.slider {
  margin: 0 auto;
  width: 100%;

  .slick-dots {
    position: relative;
    bottom: 0;

      li {
      button::before {
        border: 2px solid $cerulean;
        border-radius: 50%;
        color: transparent;
        opacity: 1;
        font-size: 28px;
        width: 15px;
        height: 15px;
      }

      &.slick-active {

        button::before {
          border: 2px solid $cerulean;
          background-color: $cerulean;
          border-radius: 50%;
          color: $cerulean;
          opacity: 1;
        }
      }

    }
  }

  &--white {
    .slick-dots {
      position: relative;
      bottom: 0;
  
        li {
        button::before {
          border: 2px solid $white;
          border-radius: 50%;
          color: transparent;
          opacity: 1;
          font-size: 28px;
          width: 15px;
          height: 15px;
        }
  
        &.slick-active {
  
          button::before {
            border: 2px solid $white;
            background-color: $white;
            border-radius: 50%;
            color: $white;
            opacity: 1;
          }
        }
  
      }
    }
  }
}

.slider-quotes {
  max-width: 900px;
  padding: 100px 50px;
  text-align: center;

  @include breakpoint(fromMedium) {
    padding: 170px 50px;
  }

  &--no-padding {
    padding: 0 50px;
  }

  &__title {
      @include sup-title;
      color: $white;
  }

  &__text {
      @include content-body--large;
      color: $white;
      margin: 0 auto;

      &--light {
        max-width: 600px;
      }
  }

  &__text-sub {
      @include content-body;
      color: $white;
  }
  
  &__button {
    @include button-primary;
    margin: 30px 0;

    &--white {
      color: $white;
    }
  }
}