@mixin breakpoint($point) {
    @if $point == from569 {
      @media (min-width: 569px) { @content; }
    }
    @if $point == to569 {
      @media (max-width: 568px) { @content; }
    }
    @else if $point == from569to1024 {
      @media (min-width: 569px) and (max-width:1024px) { @content; }
    }
    @else if $point == from768 {
      @media (min-width: 768px)  { @content; }
    }
    @else if $point == from1024 {
      @media (min-width: 1025px)  { @content; }
    }
    @else if $point == from1920 {
      @media (min-width: 1920px)  { @content; }
    }
    @else if $point == toMedium {
      @media (max-width: 640px) { @content; } //641px
    }
    @else if $point == toMedium {
    @media (max-width: 39.938em) { @content; } //640px
    }
    @else if $point == fromMedium {
      @media (min-width: 40em) { @content; } //640px
    }
    @else if $point == fromLarge {
      @media (min-width: 64.063em) { @content; } //1025px
    }
    @else if $point == toLarge {
      @media (max-width: 1024px) { @content; } //1025px
    }
    @else {
      @media (min-width: $point) { @content; } //custom
    }
  }