.custom-dropdown {
  color: $silver;
  display: inline-block;
  font: 300 16px 'NeoSansStd', arial;
  height: 48px;
  position: relative;
  vertical-align: middle;
  width: 100%;

  &::before,
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
  }

  &::before {
    background: $white;
    bottom: 0;
    height: 40px;
    right: 5px;
    top: 0;
    width: 40px;
  }

  &::after {
    background: url('/src/img/icons/arrow-down.svg') no-repeat 15px center;
    content: '';
    height: 30px;
    line-height: 1;
    margin-top: -18px;
    right: 2px;
    top: 50%;
    transform: scale(.6);
    width: 50px;
  }
}

.custom-dropdown__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  border: 0;
  border-radius: 0;
  color: $black;
  height: 40px!important;
  width: 100%;
}
