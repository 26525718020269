
#page__hero {
  @include breakpoint(1025px){
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:0;
  }
  .ie & {
    @include breakpoint(1025px){
      position:relative;
    }
  }
}
.hero  {
    position:relative;
    background:#000;

    .content {
      text-align: center;
      margin: 0 20px;

      @include breakpoint(fromMedium) {
        max-width: 600px;
        margin: 0 auto;
      }

      @include breakpoint(fromLarge) {
        max-width: 1000px;
      }

    }

    .panel {
      position:relative;
      width:100%;
      overflow:hidden;
      @include breakpoint(fromMedium){
        background-size: 240% auto;
        min-height: 500px
      }
      @include breakpoint(1025px){
        background-size: 110% auto;
      }
    }
    .video {
    display:none;
    @include breakpoint(fromMedium){
      display:block;
      height:100%;
      overflow: hidden;
      position: absolute;
      top:0;
      width:100%;
      left:0;

      video{
        height: 100%;
        left: 0;
        position: absolute;
        width: auto;

        @include transition(top 400ms ease,filter 500ms ease, opacity 500ms ease);
        @include breakpoint(1024px){
          width:auto;
          height:110%;
          opacity:0.4;
        }
        @include breakpoint(1700px){
          width:auto;
          height: 110%;
        }
        @media (min-width: 1024px) and (max-width: 1700px) and (max-height: 800px ){
          height:120%;
        }

        &.blurred {
          -webkit-filter:blur(20px) grayscale(100%);
          filter:grayscale(100%);
          opacity:0.5;
        }
      }

      &__inner {
        video {
          height: 120%;
          min-height: 720px;

          @include breakpoint(1500px){
            height: auto;
            width:100%;
          }
        }
      }
    }
  }
    .scrollDown {
      @include breakpoint(568px){
        display:none
      }

    }
    &.parallax-block {
        @include breakpoint(from1024){
            position:fixed;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
        }
    }
    .slick-slider {
        margin:0;
    }
    .overlay {
        position:absolute;
        top:0;
        left:0;
        width:250%;
        height:250%;
        background:url(/src/img/bg-tile.png) repeat 0 0;
        z-index:25
    }
    .bar {
        height:5px;
        background:#111;
        color: $radical-red;
        text-align:center;
        width:100%;
        position:absolute;
        bottom:0;
        left:0;
        z-index: 11;
        @include breakpoint(from1024){
            bottom:68px;
        }
        .level.start {
            height:5px;
            width: 0px;
            background: $radical-red;
            -webkit-animation: level 5s ease-out forwards;
               -moz-animation: level 5s ease-out forwards;
                -ms-animation: level 5s ease-out forwards;
                 -o-animation: level 5s ease-out forwards;
                    animation: level 5s ease-out forwards;
            -webkit-animation-iteration-count: 1;
               -moz-animation-iteration-count: 1;
                -ms-animation-iteration-count: 1;
                 -o-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                    animation-delay: 500ms;
                    -moz-animation-delay: 500ms;
                    -webkit-animation-delay: 500ms
        }
    }
    .animate-in {
      opacity:0;
    }
    .slide {
        background-repeat:no-repeat;
        background-position:center top;
        background-size:100% auto;
        background-color:#111;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @include breakpoint(568px){
          background-size:auto 100%;
          background-position:left top;
        }
        @include breakpoint(fromLarge){
          background-size:150% auto;
          background-position:center top;
        }
        @include breakpoint(from1920){
            background-size:130%
        }
    }

    h1 {
      @include content-title;
      color:#fff;
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      margin: 0;
      visibility: hidden;

      @include breakpoint(fromMedium) {
        font-size: 48px;
      }

      @include breakpoint(fromLarge) {
        font-size: 60px;
        
        .home & {
          font-size: 80px;
        }
      }
    }

    h2 {
      @include content-title;
      color:#fff;
      font-weight: 300;
      margin: 10px 0;
      visibility: hidden;

      @include breakpoint(fromMedium) {
        font-size: 38px;
      }
    
      @include breakpoint(fromLarge) {
        font-size: 48px;
      }
    }
}


@keyframes level {
    0% {width: 0px;}
    100% {width: 100%;}
}
@-moz-keyframes level {
    0% {width: 0px;}
    100% {width: 100%;}
}
@-webkit-keyframes level {
    0% {width: 0px;}
    100% {width: 100%;}
}


.scroll-down-arrow {
    display:block;
    position:absolute;
    top:-110px;
    left:50%;
    z-index:500;
    margin-left:-20px;
    transition: opacity 400ms ease;
    animation: bounce 2s infinite;
	  -webkit-animation: bounce 2s infinite;
	  -moz-animation: bounce 2s infinite;
	  -o-animation: bounce 2s infinite;
    .layerOne {
        fill:none
    }
    svg {
        fill:#fff;
        width:50px;
        height:50px;

    }
    &:hover {
      opacity:0.5;
      .layerOne  {
          fill:#000;

      }
    }
    .layerThree {
        animation: scroll-down-arrow 2s infinite;

        .webkit & {
          animation:none;
        }
        @keyframes scroll-down-arrow {
          0%   {
            opacity: 0;
            transform: translate3d(0,-16px,0);
          }
          50%   {
            opacity: 1;
            transform: translate3d(0,0,0);
          }
          100% {
            opacity: 0;
            transform: translate3d(0,13px,0);
          }
        }

    }
}

.slick-dots {
  li {
    button {
      &::before {
        font-size:34px;
      }
    }
  }

}
