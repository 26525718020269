.contact-form {
  bottom: 0;
  color: $white;
  flex-direction: column;
  height: 0;
  justify-content: center;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;

  @include breakpoint(fromLarge) {
    overflow: hidden;
  }

  &--active {
    display: flex;
    height: 100%;
  }

  &__overlay-bg {
    background: $mariner;
    border-radius: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;

    @include breakpoint(fromLarge) {
      border-radius: 50%;
      height: 0;

      &--active {
        height: 150%;
        transform: scale(2);
        transition: width 0.4s ease-out;
        width: 100%;
      }
    }

    &--active {
      min-height: 890px;
      transition: height 0.4s ease-out, width 0.4s ease-out;
      width: 100%;

      @include breakpoint(fromLarge) {
        min-height: 0;
      }
    }
  }

  &__close {
    position: absolute;
    right: 33px;
    top: 36px;

    @include breakpoint(fromMedium) {
      top: 10px;
    }

    svg {
      stroke-width: 2;
      width: 23px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 1200px;
    opacity: 0;
    position: relative;
    transition: opacity 0.5s ease-in-out;
    width: 100%;

    .contact-form--active & {
      opacity: 1;
      transition-delay: 0.6s;
    }

    @include breakpoint(fromLarge) {
      flex-direction: row;
      margin: 0 auto;
    }
  }

  &__item {
    padding: 20px;
    transition: margin-left 0.5s ease-in-out;
    width: 100%;

    @include breakpoint(fromLarge) {
      margin-left: -20px;
      width: 60%;
    }

    &--first {
      @include breakpoint(fromLarge) {
        width: 40%;
      }
    }

    &--form {
      padding: 0 20px;
    }

    .contact-form--active & {
      margin-left: 0;
      transition-delay: 0.7s;
    }
  }

  &__title {
    @include content-title;
    align-items: center;
    display: flex;
    margin: 10px auto;
  }

  input,
  textarea,
  select {
    color: $dove-grey;
  }

  .ninja-forms-field {
    border-radius: 5px;
    padding: 10px;
  }

  &__linkedIn {
    margin-left: 50px;
    width: 40px;
  }
}

nf-fields-wrap {
  display: block;
  grid-gap: 8px;

  @include breakpoint(fromLarge) {
    display: grid;
  }
}

nf-field {
  color: #fff;
  grid-column: 1;
  height: 45px;

  &:nth-child(5) {
    grid-row: 1 / 4;
    grid-column: 2;
    margin: 0 2px;
  }
  &:nth-child(6) {
    grid-column: 2;
  }

  input,
  select,
  textarea {
    border: none;
  }

  textarea {
    max-height: 144px;
  }

  .submit-container {
    input[type="button"] {
      @include button-primary;
      background-color: $white;
      border-radius: 5px;
      background-image: linear-gradient(to right, transparent 52%, $white 50%);
      border: 0;
      margin: 0;
      width: 100% !important;

      &:hover {
        background-color: $white;
        background-position: -100% 100%;
        color: $mine-shaft;
      }
    }
  }
}

.nf-field-container {
  &.textarea-container {
    margin-bottom: 0;
  }

  &.listselect-container {
    margin-bottom: 15px;
  }
}

.nf-after-field .nf-error-msg {
  margin-right: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.nf-error-msg.nf-error-field-errors {
  color: #fff;
}

.offices-table {
  display: flex;
  flex-direction: column;
  @include content-body--large;
  font-weight: 400;

  &__telephone {
    @include content-body;
    color: $white;
    float: right;
    margin-right: 30px;

    & a {
      color: $white;
    }
  }
}
