.display-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__container{
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
  }

  &__hide {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }

  &__show {
    margin-top: 20px;
    max-height: 2000px;
  }

  &__link {
    @include button-secondary;
    cursor: pointer;
  }
}