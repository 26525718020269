/*-------------------------------
	Work Page
---------------------------------*/
.work-page {
  background:#1b1b1b;
  transition:background 400ms ease;
}
.cssanimations.work-page {
  background:#222;
}

.work-page .mobileFooterImage,
.newsPage .mobileFooterImage,  {
    background: #ffffff
}

.work__hero {
  background:$mariner;
  color:#fff;

  height:275px;
  padding:80px 0 20px;
  text-align:center;
  @include breakpoint(568px){

  }
  @include breakpoint(1025px){
    text-align: center;
    padding:130px 0 0;
    max-height:450px;
    height: 320px;
  }
  .slide {
    height:150px;
    @include breakpoint(1024px){
      height:170px;
    }
  }
  .hero-slider {
    opacity:0;
    transition: opacity 500ms ease;
    &.visible {
      opacity:1;
    }
  }
}