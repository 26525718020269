.text-panel {
  color: $mine-shaft;
  margin: 0 auto;
  padding: 0 14px;
  text-align: center;
  max-width: 950px;

  .content-band--tertiary & {
    color: $white;
  }

  &__title {
    @include content-title;
    color: $mine-shaft;

    .content-band--tertiary &,
    .content-band--quaternary & {
      color: $white;
    }
  }

  &__body {
    @include content-body;
    color: $mine-shaft;

    &--large {
      @include content-body--large;
    }

    &--large-strapline {
      @include content-body--large-strapline;
    }

    &--strong {
      @include content-body-strong;
    }

    .content-band--tertiary &,
    .content-band--quaternary & {
      color: $white;
    }
  }

  &__sup-title {
    @include sup-title;
  }

  &__button {
    @include button-primary;
  }
}