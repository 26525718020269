.icon-list-featured {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 50px 20px 50px 10px;
  max-width: 1200px;
  text-align: center;
  width: 100%;

  @include breakpoint(fromMedium) {
    margin: 120px auto;
  }

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 20%;

    @include breakpoint(fromMedium) {
      width: 25%;
    }

    &--plus {
      @include content-body--large;
      color: $cerulean;
      margin-top: 20px;
      width: auto;

      @include breakpoint(fromLarge) {
        margin-top: initial;
        font-size: 50px;
      }
    }
  }

  &__image {
    max-height: 50px;
    width: auto;
    // max-width: 88px;

    @include breakpoint(fromMedium) {
      max-height: 62px;
    }
  }

  &__title {
    @include content-title;
    color: $mine-shaft;
    font-size: 16px;
    text-align: left;

    @include breakpoint(fromMedium) {
      font-size: 18px;
    }

    @include breakpoint(fromLarge) {
      font-size: 26px;
    }

    .content-band--tertiary & {
      color: $white;
    }
  }
}
