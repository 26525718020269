@at-root .burger-menu {
    display: inline-block;
    transition: opacity 0.15s linear;
    z-index: 1200;
    
    @at-root .burger-menu__container {
        cursor: pointer;
        display: inline-block;
        height: 22px;
        position: relative;
        width: 30px;
      }
  
      @at-root .burger-menu__inner {
        display: block;
        top: 2px;
        width: 30px;
        height: 2px;
        background-color: $white;
        position: absolute;
        transition: background-color 0.7s ease, transform 0.7s;
  
        &::before {
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          top: 8px;
          background-color: $white;
          position: absolute;
          transition: background-color 0.7s ease, transform 0.7s ease, opacity 0.15s 0.4s ease, top 0.2s ease;
        
            @include breakpoint(fromLarge) {
                top: 9px;
            }
        }
  
        &::after {
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          top: 16px;
          background-color: $white;
          position: absolute;
          transition: background-color 0.7s ease, transform 0.7s, top 0.2s ease;

            @include breakpoint(fromLarge) {
                top: 18px;
            }
        }
      }
  
        @at-root .burger-menu__inner--active {
            height: 2px;
            -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
            transform: translate3d(0, 10px, 0) rotate(135deg);
            transition-delay: 0.1s;
            @include breakpoint(fromMedium){
                background-color: $white;
            }

            &::before {
                transition-delay: 0s;
                height: 2px;
                opacity: 0;
                top: 10px;
                @include breakpoint(fromMedium){
                    background-color: $white;
                }
            }

            &::after {
                -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
                transform: translate3d(0, -20px, 0) rotate(-270deg);
                transition-delay: 0.1s;
                top: 20px;
                height: 2px;
                @include breakpoint(fromMedium){
                    background-color: $white;
                }
            }
        }
  }