.button,
.wpcf7 form input[type="submit"] {
    max-width:300px;
    padding: 10px 63px;
    margin:10px 0;
    text-align:center;
    color:#fff;
    background:none;
    border:3px solid $mariner;
    text-shadow:none;
    @include transition(all 300ms ease);
    &:hover {
      background:$mariner
    }
    &:visited {
      color:#fff;
    }
    &.outline {
      &.green {
        border:2px solid $cerulean;
      }
    }
    &.grey {
      border-color:#999;
      border-width:2px;
      color:#999;
      &:hover {
        background:none;
        color:#333;
        border-color:#333;
      }
    }

}
