#page__hero {
  .content {
    @media (max-width: 767px) {
      top:50%;
    }
    @media (max-width: 767px) and (orientation: landscape){
      margin-top: 0;
    }
    @include breakpoint(1025px){
      min-height: auto;
    }
  }
  .video {
  }
  .bar {
    bottom:0;
  }
  .panel {
    max-height: 362px;

    &--full {
      max-height: 100vh;
    }

    @include breakpoint(fromMedium){
      max-height: 500px;
    }

    @include breakpoint(1025px){
      margin-top: 0;
      max-height: 720px;

      &--full {
        max-height: 100vh;
      }
    }
  }
  .slide {
    background-size: cover;
    height: 100vh;
    max-height: 380px;

    &--full {
      max-height: 100vh;
    }

    @include breakpoint(fromMedium){
      max-height: 500px;
    }

    @include breakpoint(1025px){
      max-height: 720px;

      &--full {
        max-height: 100vh;
      }
    }
  }
}