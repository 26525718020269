.header {
  background-color: rgba(27,27,27,0);
  height: 100px;
  padding: 0 30px;
  position: fixed;
  top: 0;
  transition: background-color .6s ease, height .6s ease;
  width: 100%;
  z-index: 1050;

  @include breakpoint(fromMedium) {
    padding: 0 40px;
  }

  @include breakpoint(fromLarge) {
    padding: 0 50px;
  }

  &--is-active {
    background-color: rgba(27,27,27,1);
    height: 60px;
  }

  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    height: 40px;
    transition: width .6s ease;
    max-width: 200px;
    width: 100%;

    @include breakpoint(toMedium) {
      max-width: 150px;
    }
  }

  &__button {
    @include button-secondary;
    margin: 0;
    min-width: 200px;
    transition: opacity .6s ease, background-position .3s cubic-bezier(.19, 1, .22, 1) .1s, color .5s ease 0s, background-color .5s ease;
    
    @include breakpoint(toMedium) {
      display: none;
    }
    
    @include breakpoint(fromLarge) {
      min-width: 250px;
    }

    .header--is-active & {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include breakpoint(fromMedium) {
      width: 280px;
    }

    @include breakpoint(fromLarge) {
      width: 330px;
    }
  }
}

