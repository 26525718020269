
.news__page {
  &.terms-page {
    background:#000;
  }
  .section-title {
    margin:0 0 30px;
  }
  .hero {
    text-align: center;
    background-repeat: no-repeat;
    background-position: center 0;
    background-color: #050505;
    height:258px;
    max-height:100%;
    padding:0 20px;
    @include breakpoint(fromMedium){
      height:290px;
    }
    @include breakpoint(from1024){
      position:fixed;
      top:68px;
      left:0;
      z-index:-1;
      width: 100%;
      height:612px;
    }
    .panel {
        @include breakpoint(from1024){
            margin-top:38px;
        }
    }
    .wrapper-inside {
        max-width:880px;
        margin: 0 auto;
        @include breakpoint(from1024){
          height: 130px;
          left: 50%;
          margin: -135px 0 0 -310px;
          max-width: 880px;
          position: absolute;
          top: 50%;
          width: 620px;
        }
        @include breakpoint(2000px){
          width:770px;
          margin-left:-385px
        }
    }
    h1 {
      color:#fff;
      font-size:30px;
      line-height: 1.5;
      text-align: center;
      margin:55px auto 0;
      @include breakpoint(fromMedium){
        margin:105px auto 0;
      }
      @include breakpoint(from1024){
        font-size:34px;
        margin:0
      }
      @include breakpoint(2000px){
        font-size:48px;
      }
    }
    h2 {
      color:#fff;
      font-size:15px;
      text-align: center;
      line-height:1.4;
      margin:5px auto 35px;
      @include breakpoint(from1024){
          margin:19px auto 0;
          max-width: 610px
      }
    }
  }
  .latest-news {
      background:#fff;
      padding:0 20px;
      .wrapper {
        max-width: 650px;
        margin: 0 auto;
        border-bottom:1px solid #ccc;
        padding:20px 0;
      }
      &.first {
        .wrapper {
          padding-top:0;
        }
      }
      &.no-posts {
        .wrapper {
          border-bottom:none;
          margin-bottom: 80px;
        }
      }
      h3 {
        font-size: 25px;
        margin: 15px 0;
        a {
          color:#333;
          &:hover {
            color:#666;
          }
        }

      }
  }
  .title-holder {
    text-align: center;
    .wrapper {
      border-bottom:none;
      padding:0;
    }

  }
  .parralaxTop {
      background:#fff;
      padding:70px 0;
      min-height:720px;
      @include breakpoint(fromMedium){
        padding:110px 0 70px;
      }
      @include breakpoint(from1024){
          z-index:10;
          margin-top: 430px;
      }
  }
  .page-controls-next,
  .page-controls-prev {
      background-color: #f0f0f0;
      &.active {
          width:330px;
      }
      .label {
          width:230px;
      }
      .holder {
          background-color: #f0f0f0;

      }
  }
  .post-date {
      text-align: center;
      color:#999;
      font-family: 'NeoSansStd' ,sans-serif;
      font-weight: 300;
  }
  .thank-you-message {
    text-align:center;
    margin:90px 0;
    .thumbs-up {
      fill: #333333;
      height: 45px;
      width: 45px;
    }
    .title {
      color: #333333;
      font-size: 41px;
      line-height: 46px;
      margin: 15px 0 20px;
    }
    p {
      font-size:17px;
      color:#999;
      margin:0 0 60px;
      font-family: 'NeoSansStd' ,sans-serif;
      font-weight: 300;
    }
    .button {
      color:#999;
      border-color:#999;
      padding:10px 43px;
      &:hover {
        color:#333;
        border-color:#111;
        background:none;
      }
    }
  }
  .shin {
    background:#ececec;
    padding:30px 0;
    font-size:14px;
    line-height:23px;
    @include breakpoint(from1024){
      padding:80px 0
    }
    &.no-posts {
      .row {
        max-width: 690px;
      }
    }
    .panel-one {
      display:block;
    }
    .panel-two {
      display:none
    }
    h2 {
      font-size:24px;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      width:100%;
      padding:10px;
      display: block;
      appearance:none;
      -webkit-appearance:none;
      border:1px solid #ccc
    }
    .form {
      .screen-reader-response {
        display:none;
      }
      //padding:20px 0;
      .ajax-loader {
        position:relative;
        width:15px;
        height:15px;
        margin:20px auto;
        display: block;
        @include breakpoint(fromMedium){
          position:absolute;
          right: 39px;
          top: 63px;
          margin:0;
        }

      }
      p {
        margin:0;
        font-size:14px;
      }
      label {
        color: #666;
        display: block;
        font-size: 12px;
        margin: 30px 0 4px;
        text-transform: none;
      }
      .upload-footnote {
        color: #999;
        font-size: 80%;
        margin:20px 0;
      }
      .button {
        width:100%;
        margin:0 auto;
        max-width:200px;
        display:block;
        @include breakpoint(fromMedium){
          margin-top:50px;
        }
      }
    }
  }
}

.news__page-inner {
  .wrapper {
    padding-top:20px;
  }
  h1 {
    @include content-title;
    margin: 0 auto;
    text-align:center;
  }
  .post-date {
    border-bottom:1px solid #ccc;
    padding-bottom: 30px;
  }
  .latest-news,
  .shin {
    padding:100px 0;
    .row {
      max-width:650px;
    }
  }

  .content {
    font-size:18px;
    h2 {
      @include content-body-strong;
      font-size:22px;
      color:#222;
    }
    h3 {
      font-size:18px;
      color:#222;
    }
    ul {
      padding:0;
      margin:0 0 15px 15px;
    }
    li {
      padding-left:15px;
    }
  }

}
