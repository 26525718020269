/*-------------------------------
    Accordians
---------------------------------*/
.mobile-accordian {

    .accordion-toggle {
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid #e4e4e4;
        .home & {
          border-bottom: none;
        }
        @include breakpoint(fromMedium){
            cursor: default;
            border-bottom: none;
        }
        &::after {
          background:url(/src/img/down-arrow.svg) no-repeat 0 0;
          content: "";
          background-size:21px;
          display: block;
          float: right;
          height: 20px;
          position: relative;
          top: 8px;
          width: 20px;
          @include breakpoint(fromMedium){
            display:none;
          }
        }
        &.active {
          border-bottom: none;
          &::after {
            top: -2px;
            transform: rotate(-180deg);
          }
        }
    }
    .accordion-content {
        display:none;

        &.active {
          overflow:initial;
          margin:0 0 10px;
          display:block;
          //border-bottom: 1px solid #e4e4e4;
          @include breakpoint(fromMedium){
            border-bottom:none;
          }
        }
        @include breakpoint(fromMedium){
          display:block;
          height:auto;
          overflow:initial;
          max-height:100%;
          .home & {
            display:block;
          }
        }
        &.default {
          display: block;
        }
    }
}
