.content-connect-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-13%, 13%);
  max-width: 370px;
  width: 40vw;

  @include breakpoint(fromMedium) {
    width: 24vw;
  }

  .content-connect-logo__path {
    &--dark {
      fill: $mariner;
      opacity: 0;
      transform:rotate(-30deg);
      transform-origin: center;
      transition: transform .8s ease-in-out, opacity .8s ease-in-out;

      .scroll-into-view-active & {
        opacity: 1;
        transform:rotate(0deg);
      }
    }

    &--light {
      fill: $cerulean;
      opacity: 0;
      transform: scale(0.2);
      transform-origin: center;
      transition: transform .4s ease-in-out, opacity .4s ease-in-out;
      transition-delay: .8s;

      .scroll-into-view-active & {
        opacity: 1;
        transform: scale(1);
      }
    }

    &--light-wave {
      fill: $cerulean;
      opacity: 0;
      transform: scale(.9);
      transform-origin: center;
      transition: transform .8s ease-in-out, opacity .8s ease-in-out;
      transition-delay: 1.2s;
  
      .scroll-into-view-active & {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

}