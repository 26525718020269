@mixin button {
  background-size: 200% 100%; 
  border: 4px solid;
  border-radius: 40px;
  display: inline-block;
  font: 20px/1 'NeoSansStd', sans-serif;
  margin: 30px 0 0;
  padding: 10px 60px;
  pointer-events: auto;
  text-align: center;
  text-decoration: none;
  transition: background-position .3s cubic-bezier(.19, 1, .22, 1) .1s, color .5s ease 0s, background-color .5s ease;

  .safari &
  .mac & {
    // Font Face Padding Issue
    padding: 12px 60px 10px;
  }
    
    &:hover {
      background-position: -100% 100%;
    }

    .content-band--tertiary & {
      color: $white;
    }

    .content-band--spacing-small & {
      margin-bottom: 20px;
    }
}

@mixin button-primary {
    @include button;
    color: $mine-shaft;
    border-color: $radical-red;
    background-image: linear-gradient(to right, transparent 50%, rgb(245, 56, 85) 50%);

    &:hover {
        background-color: rgb(245, 56, 85);
        background-position: -100% 100%;
        color: $white;
    }

    .content-band--tertiary & {
        color: $white;
    }
}

@mixin button-secondary {
    @include button;
    color: $white;
    border-color: rgba(255,255,255,.5);
    background-image: linear-gradient(to right, transparent 50%, rgba(255,255,255,0) 50%);
    
    &:hover {
        background-color: rgba(255,255,255,1);
        background-position: -100% 100%;
        color: $mine-shaft;
    }
    
    .content-band--primary &,
    .content-band--secondary & {
        color: $mine-shaft;
        border-color: rgba(55,55,55,.5);
        background-image: linear-gradient(to right, transparent 50%, rgba(55,55,55,0) 50%);
        
        &:hover {
            background-color: rgba(55,55,55,1);
            color: $white;
        }
    }
}