$blue: #0397d7;
$red: #a90b22;


body {
    font-family: 'NeoSansStd', sans-serif;
    font-weight: 300;
    height:100%;
    overflow-x:hidden;
    -webkit-overflow-scrolling: touch;
    
    &.lock {
      @include breakpoint(toLarge){
        position:fixed;
        width: 100%;
        height: 100%;
      }
      @include breakpoint(1025px){
        overflow:hidden;
      }
    }
}
a {
    color:#0A6CB4;
    text-decoration:none;
    outline: 0
}
a:hover {color:#00AEEF}

.lowerCase {
    text-transform:lowercase
}
.site-wrapper {
    min-width:320px;
    overflow: hidden;
    width: 100%;
    -webkit-box-shadow: -10px 0 40px 0 rgba(000,000,000,0.5);
    box-shadow: -10px 0 40px 0 rgba(000,000,000,0.5);
    @extend .clearfix;
    @include breakpoint(fromMedium){
      box-shadow: none;
      -webkit-box-shadow:none;
    }
}
.mobileFooterImage {
    display: block;
    width: 100%;
    background: #111111
}
.outer-wrapper {
  opacity:0;
  visibility: hidden;
  transform:scale(0.98) translate3d(0,-50px,0);
  transition: opacity 500ms ease,transform 750ms ease,visibility 500ms ease;
  &.active {
    opacity:1;
    visibility: visible;
    transform: none;
  }
  &.shrink {
    opacity:0;
    visibility: hidden;
    transform:scale(0.95) translate3d(0,10px,0);
  }
}

.featurePanel {
    padding:80px 0;
    text-align:center;
    position: relative;
    @include breakpoint(fromMedium){
      padding:80px 20px;
    }
}
h1{
    text-transform:none
}
*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box
}

@include breakpoint(from1024){
    .wrapper {
        max-width:1360px;
        margin:0 auto;
        position:relative
    }
    .wrapper-inside {
        position:relative
    }
}


