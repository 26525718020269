@mixin breakpoint($point) {
  @if $point == from569 {
    @media (min-width: 569px) { @content; }
  }
  @if $point == to569 {
    @media (max-width: 568px) { @content; }
  }
  @else if $point == from569to1024 {
    @media (min-width: 569px) and (max-width:1024px) { @content; }
  }
  @else if $point == from768 {
    @media (min-width: 768px)  { @content; }
  }
  @else if $point == from1024 {
    @media (min-width: 1025px)  { @content; }
  }
  @else if $point == from1600 {
    @media (min-width: 1600px)  { @content; }
  }
  @else if $point == from1920 {
    @media (min-width: 1920px)  { @content; }
  }
  @else if $point == toMedium {
    @media (max-width: 640px) { @content; } //641px
  }
  @else if $point == fromMedium {
    @media (min-width: 40.063em) { @content; } //641px
  }
  @else if $point == fromLarge {
    @media (min-width: 64.063em) { @content; } //1025px
  }
  @else if $point == toLarge {
    @media (max-width: 1024px) { @content; } //1025px
  }
  @else {
    @media (min-width: $point) { @content; } //custom
  }
}

@mixin sectionArrow($color){
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 35px 0;
  border-color: $color transparent transparent transparent;
  position: absolute;
  bottom:-29px;
  left:50%;
  margin-left:-32px;
  transition:bottom 500ms linear;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

