.partners {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  text-align: center;
  width: 100%;

  &__item {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    width: 25%;

    @include breakpoint(fromMedium) {
      margin: 40px 0;
      width: 20%;
    }
  }

  &__logo {
    max-width: 120px;
    width: 100%;
  }

}
