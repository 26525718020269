.product-page {
  .footer {
      background:#1b1b1b;
      @include breakpoint(fromMedium){
          background:#111;
      }
  }
  .mobile-first {
    background:#0397d7;
    .ie9 & {
      display:none;
    }
    @extend .clearfix;
    padding:50px 20px;
    @include breakpoint(fromLarge){
        padding:80px 20px;
    }
    @include breakpoint(2000px){
        padding:160px 20px;
    }
    .sliding-text {
      margin-top: 40px;
      position: relative;
      text-align: center;
      font-family: 'NeoSansStd' ,sans-serif;
      font-weight: 300;
      font-size:20px;
      @include breakpoint(fromLarge){
        font-size:28px;
      }
      & > span {
        transform:translate3d(0,50px,0);
        opacity:0;
        color:#fff;
        display:inline-block;
        margin:0 5px;
        transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
      }
      .first {
        transition-delay:500ms;
      }
      .second {
        transition-delay:750ms;
        color:#98defd;
      }
      &.active {
        .first {
          transform:translate3d(0,0,0);
          opacity:1;
        }
        .second {
          opacity:1;

          transform:translate3d(0,0,0);
        }
      }
    }
  }
  .cta-footer {
      background: url(/build/img/mt-bg-tile-large.png) repeat #222222;
      background-size:30%;
      padding:50px 0;
      text-align: center;
      color:#fff;
      @extend .clearfix;
      @include breakpoint(fromMedium){
        background-size:auto;
        padding:150px 0;
      }
      .button {
          display:block;
          float:none;
          margin:0 auto;
          max-width: 250px;
          padding: 15px;
          border-color:#0397d7;
          &:hover {
              background:#0397d7;
              color:#fff;
          }
      }

  }
  .deviceContainer {
    max-width:62px;
    height:62px;
    overflow:hidden;
    margin:20px auto;
    position:relative;
    -webkit-transition: all 900ms linear;
    -moz-transition: all 900ms linear;
    -ms-transition: all 900ms linear;
    -o-transition: all 900ms linear;
    transition: all 900ms linear;
    &.active {
        max-width:430px;
        @include breakpoint(fromLarge){
          max-width:560px
        }
        svg {
            margin-left:0;
        }
        svg.desktop {
          right:0;
          opacity:1;
          visibility: visible;
          @include breakpoint(400px){
            right:8%;
          }
        }
        svg.laptop {
          opacity:1;
          right:26%;
          visibility: visible;
          @include breakpoint(400px){
            right:32%;
          }
        }
        svg.tablet {
          opacity:1;
          left:22%;
          visibility: visible;
          @include breakpoint(400px){
            right:26%;
          }
          @include breakpoint(fromLarge){
            left:31%
          }
        }
        svg.phone {
          opacity:1;
          left:-3%;
          @include breakpoint(400px){
            left:5%;
          }
          visibility: visible;
        }
    }
    svg {
        margin:0 0 0 -37px;
        position:absolute;
        top:0;
        transition: visibility 900ms ease, opacity 2s ease, left 900ms ease, right 900ms ease;
        &.tablet {
          left:50%;
        }
        &.phone {
          left:45%;
          width:auto;
        }
        &.desktop {
            right:50%;
        }
        &.laptop {
          right:49%;
          height: 75px;
          top: -7px;
          width: 75px;

        }
    }
    svg.tablet,
    svg.laptop,
    svg.desktop {
        opacity:0;
        visibility: hidden;
    }
  }
  .secondary-quote {
      background: #0397d7;
      display:none;
      color:#fff;
      text-align: center;
      .row {
          max-width:750px;
      }
      @include breakpoint(fromMedium){
          display: block;
          padding:30px 0;
      }
      @include breakpoint(fromLarge){
          padding:70px 0;
      }
      h2 {
          font-size:33px;
      }
      h3 {
          color:#05608e;
          font-size:25px;
      }
  }

}
.products__intro{
    background:#fff;
    @include breakpoint(fromLarge){
        margin-top: 720px;
        position: relative;
        z-index: 1;
    }
    .mobile-accordian {
        @extend .clearfix;
    }
    .accordion-toggle {
        padding:13px 0;
        margin:0;
        font-size:20px;
        color: #2e2e2e;
        &.active {
            border-bottom: none;
            @include breakpoint(fromMedium){
                margin:0;
            }
        }
        .desktop {
            display:none;
        }
        @include breakpoint(fromMedium){
            .mobile {
                display:none;
            }
            .desktop {
                display:block;
            }
        }
        @include breakpoint(fromLarge){
            font-size:28px;
        }
    }
    .accordion-content {
        padding:0;
        border-bottom:1px solid #e4e4e4;
        &.active {
          padding:13px 0;
        }
        @extend .clearfix;
        @include breakpoint(fromMedium){

            border:none;
            position: relative;
        }
        p {
            color:#666;
            font-size: 14px;
            margin:0 0 10px;
            line-height:1.6;
            @include breakpoint(fromMedium){
                font-size:15px;
            }
        }
    }
    .newline {
        display:block;
        @include breakpoint(fromMedium){
            display:inline;
        }
    }
    .desc {
        @include breakpoint(fromMedium){
            padding:50px 30px;
            max-width:auto;
            margin:0 auto;
        }
        @include breakpoint(fromLarge){
            padding:40px 20px;
        }
    }
    .custom-solution {
        background:#fff;
        text-align: center;
        padding:75px 20px 100px;
        .section-title {
          @include breakpoint(fromMedium){
            display:inline-block;
            margin:0 0 50px;
          }
        }
        p {
          font-family: 'NeoSansStd' ,sans-serif;
          font-weight: 300;
          font-size:20px;
          max-width: 520px;
          color:#555;
          margin:0 auto;
          @include breakpoint(fromLarge){
            font-size:27px;
          }
        }
    }
    .dealer-websites,
    .cms,
    .eshop  {
        @include breakpoint(fromMedium){
            background:#eceaea;
            .accordion-toggle,
            .accordion-content {
                text-align: left;
            }
        }
        .accordion-toggle {
            @include breakpoint(fromMedium){
                margin:0 0 10px;
            }
        }
        .accordion-content {
            @include breakpoint(fromMedium){
                max-width:400px;
            }
        }

    }
    .dealer-websites {
      background:#eceaea;
      position:relative;
      margin-bottom:50px;
      padding-bottom: 20px;
      &::after {
        @include sectionArrow(#eceaea);
      }
      @include breakpoint(fromMedium){
        margin-bottom:0;
        padding-bottom: 0;
        &::after {
          margin-left: -310px;
        }
      }

      .accordion-toggle {
        pointer-events:none;
        cursor:default;
        border-bottom:none;
        &::after {
          display:none;
        }
      }
      .accordion-content {
        border-bottom:none;
          @include breakpoint(fromMedium){
              max-width:100%;
          }
          @include breakpoint(fromLarge){
              padding:20px 0 150px;
          }
      }
      .content {
          @include breakpoint(fromLarge){
              float:left;
              width:30%;
          }
      }
      .desc {
        @include breakpoint(fromMedium){
          padding:20px 30px;
        }
        @include breakpoint(fromLarge){
          padding:100px 30px 20px;
          max-width: 1220px;
          min-height:560px;
        }
        @include breakpoint(1600px){
          min-height:610px;
        }

      }

    }
    .product-slider {
      display: none;

      &.mobile {
        margin: 50px auto 0;
        max-width: 730px;
        display:block;
        @include breakpoint(fromMedium){
          margin-bottom:0;
        }
        @include breakpoint(1025px){
          display:none
        }
      }
      @include breakpoint(1025px){
        display:block;
        position: absolute;
        top:-100px;
        right:0;
        width:50%;
      }
      @include breakpoint(1600px){
        right: -100px;
        top: -100px;
        width: 740px
      }
    }
    .phone {
        //display:none;
        @include breakpoint(1023px){
            display: block;
            position: absolute;
            left:auto;
            bottom:-231px;
            right:20px;
            width:270px;

        }
    }
    .dealer-locators {
      .accordion-content {
        @include breakpoint(fromLarge){
          max-width: 80%;
        }
      }
    }
    .phone-video {
      width:100%;
      height:auto;
    }
    .service-booking {
        @include breakpoint(fromMedium){
          background:url('/src/img/products-interior.jpg') no-repeat center top #000;
          .accordion-toggle {
              color:#fff;
          }
          p {
              color:#ccc;
          }
        }
        .phone {
          display:none;
          @include breakpoint(1023px){
            display:block;
            transition: transform 1s ease;
            transform:translate3d(0,0,0);
            &.visible {
              transform:translate3d(0,-97px,0);
            }
          }
        }
    }


    .service-booking {
      .row {
        max-width:1040px
      }
      @include breakpoint(fromMedium){
        padding:157px 30px 197px;
      }
        .desc {
          max-width: auto;
          @include breakpoint(fromMedium){
              padding:0 20px;
          }
        }
        .accordion-content {
          @include breakpoint(fromMedium){
              max-width:480px;
          }
        }
        .accordion-toggle {
          @include breakpoint(fromMedium){
              padding-bottom:10px
          }
        }
    }
    .main.row {
        max-width:980px;
    }

    .vl {
      @include breakpoint(fromMedium){
        background: url(/build/img/mt-bg-tile-large.png) repeat #222222;
        height:680px;
        .accordion-toggle {
          color:#fff;
          text-align:left;
        }
        .accordion-content {
          text-align:left;
        }
        p {
            color:#ccc;
        }


      }
      .desc {
        @include breakpoint(fromMedium){
          padding-bottom:40px;
        }
        @include breakpoint(fromLarge){
          padding:100px 30px
        }
      }
    }

    .mac-holder {
        position: relative;
        max-width:1020px;
        background:#1b1b1b;
        margin-top:70px;
        padding-top:30px;
        @include breakpoint(fromMedium){
          background: none;
          margin-top:0px;
          padding-top:0;
        }
    }
    .mac {
        display: block;
        width:100%;
        height:auto;
        margin:30px 0;
        @include breakpoint(fromMedium){
          display:block;
            max-width:980px;
            margin: 0 auto;
        }
    }
    .mac-video {
      display:none;
      width:100%;
      height:auto;
      margin:30px 0;
      @include breakpoint(fromMedium){
        display:none;
      }
        @include breakpoint(fromLarge){
          display:block;
            height: auto;
            left: 149px;
            position: absolute;
            top: 71px;
            width: 720px;
            margin:0;
        }
    }
}
.products__more--upper {
  max-width: 1040px;
  .vehicle-locators {
    background:#1b1b1b;
    margin-bottom:30px;
    padding-bottom:30px;
    @include breakpoint(fromMedium){
      background: none;
      padding-bottom:0;
      margin-bottom:0;
    }
    .accordion-toggle {
      border-bottom:none;
    }
    .accordion-content {
      border-bottom:none;
    }
    p,h3 {
      color:#eee;
      @include breakpoint(fromMedium){
        color:#333;
      }
    }


    .accordion-toggle {
      cursor:default;
      pointer-events:none;
      &::after {
        display:none;
      }
    }
  }

  @include breakpoint(fromLarge){

  }
}
.products__more--lower {
  max-width: 1040px;
  @include breakpoint(fromLarge){
    padding-bottom:50px;
  }
}
