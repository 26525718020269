.offcanvas-menu {
    background-color: $mine-shaft;
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, .3);
    height: 100%;
    opacity: 0;
    padding: 60px 20px 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .4s ease, width 1s ease, padding 1s ease, transform .3s cubic-bezier(0.76, -0.35, 0.42, 1.22);
    width: 100%;
    z-index: 1100;
    transform: translateX(100px);
    
    @include breakpoint(fromMedium) {
        padding: 100px 80px 0;
        width: 470px;
    }
    
    &.offcanvas-menu--active {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0);
    }
    
     .offcanvas-menu__list {
      margin: 0 auto;
      max-width: 380px;
      padding: 0;
    }
  
    .offcanvas-menu__list-item {
      border-bottom: 1px solid $dove-grey;
      list-style: none;
      margin-left: 0;
      opacity: 1;
      position: relative;
  
      &.offcanvas-menu__list-item--animated {
        margin-left: 0;
        opacity: 1;
        transition: opacity .5s ease, margin-left .5s ease;
      }
    }
  
    .offcanvas-menu__link {
      color: $white;
      display: block;
      font: 300 26px 'NeoSansStd', arial;
      padding: 20px 10px 18px 0;
      text-decoration: none;
      width: 100%;
    }

    .offcanvas-menu__burger {
      height: 60px;
      width: 66px;
  
      @include breakpoint(fromSmall) {
        height: 75px;
        padding-right: 15px;
        width: 91px;
      }
    }
}
    
  .offcanvas-menu-overlay {
    background-color: rgba(0, 0, 0, .7);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity .6s ease;
    z-index: 1000;
  
  
    &.offcanvas-menu-overlay--visible {
      cursor: pointer;
      opacity: 1;
      pointer-events: auto;
      transition: opacity .3s ease;
    }
  }
  