.contact-page {
  font-size: 16px;

  .map {
    background: $mine-shaft;
  }
  .section-wrapper {
    @include breakpoint(from1024){
      margin-top: 588px
    }
  }
  .hero {
    background: $mine-shaft;
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: center;
    padding:40px 50px 0;
    position: relative;
    @include breakpoint(fromMedium){
      height:500px;
    }
    .subtitle {
      color: #fff;
      font-weight: 400;
      text-align: center;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
    .pin {
      opacity:0;
      margin:20px auto;
      width:50px;
      height:50px;
      display:block;
    }

  }

    .partner-grid {
        background:#eceaea;
        padding:50px 0;
        h2 {
            font-size:16px;
            margin:0 0 40px  ;
            text-align: center;
            @include breakpoint(fromLarge){
                font-size:20px;
                margin:0 0 80px ;
            }
        }
        @include breakpoint(fromLarge){
            padding:140px 0;
        }
    }
}
.contact__intro{
  background:#fff;
  .column {
    padding:0;
    position:relative;
  }
  .section-title {
    font: 400 20px/1.4 'NeoSansStd', arial;
  }
  .row {
    max-width: 100%;

    &.dark {
      background-color: $alabaster;

      @include breakpoint(768px){
        background-color: initial;

        .second {
          float:left;
        }
        .first {
          float: right;
        }
      }
    }
  }
  .map {
    display:none;
    @include breakpoint(768px){
      display:block;
      height:500px;
    }
  }
  .call-to-actions {
    float: right;
    padding: 70px 10px;
    text-align:center;
    a {
      display:inline-block;
      width:100%;
      margin:5px 10px;

    }
    svg {
      width:20px;
      height:20px;
      fill:#a4a4a4;
      &.envelope {
        width:25px;
        height:25px;
      }
    }
    @include breakpoint(768px){
      display:none;
    }
  }
  .first {
    .contact-trigger {
      display:none;
      @include breakpoint(768px){
        display: inline-block;
      }
    }
    .phone-icon {
      display:none;
      @include breakpoint(768px){
        .safari & {
          margin:0;
        }
        display: inline-block;
      }
    }
  }
  .contact-trigger,
  .phone-icon {
    @include breakpoint(768px){
      display: inline-block;
      height: 30px;
      margin: 20px 5px;
      overflow: hidden;
      width: 30px;
    }

    svg {
      fill:#a4a4a4;
      width:30px;
      height:30px;
    }
    &:hover {
      fill:#666;
    }
  }
  .phone-icon {
    svg {
      height: 40px;
      margin: 5px 0 0 19px;
      width: 40px;
      @include breakpoint(768px){
        margin: 5px 0 0 10px;
      }
    }
  }
  .content {
    padding:50px 20px;
    @include breakpoint(768px){
      text-align: center;
      padding:50px;
      margin-top: 100px;
    }
  }
  .address {
    margin:0;
    color:#333;
    @include breakpoint(768px){
      margin:0 auto;
    }

    .tel {
      font-weight: 400;
    }
  }
  .clock {
    display:none;
    @include breakpoint(768px){
      display:none;
      transform:scale(0.7);
      height:150px;
      .clock-rotating-wrapper {
        transform:scale(0.5);
        left:-3px;
      }
    }
  }
}
