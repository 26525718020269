/*-------------------------------
	Contact Us / Get in touch
---------------------------------*/
.blur section,
.blur header {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: url(/src/img/blur.svg#blur);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}
#contactPanel {
  display:none;
  height:500px;
  background:url(/src/img/login/tile.png) repeat #222;
  color:#cccccc;
  position: fixed;
  top: 0;
  left:0;
  z-index: 500;
  width:100%;
  .csstransforms3d &{
    transform: none;
    -webkit-transform: none
  }
  @include breakpoint(1025px){
    top:68px;
    background:rgba(17,17,17,1);

    padding:20px 50px;
    display:none;
    height:100%
  }
  .loading-tread {
    display:none;
    @include breakpoint(1025px) {
      display:block;
    }
    position:fixed;
    top:0;
    left:0;
    width:120%;
    height:auto;
    z-index: -10;
    background:#111;
    @include breakpoint(1666px) {
      height:auto;
      width:100%;
    }
  }
  .panel-one {
    display:block;
  }
  .panel-two {
    display:none
  }
  .thank-you-message {
    text-align:center;
    margin-top:50px;
    .thumbs-up {
      fill: #999;
      height: 45px;
      width: 45px;
    }
    .title {
      color: #fff;
      font-size: 61px;
      margin: 30px 0 20px;
    }
    p {
      font-size:19px;
      color:#cccccc;
      margin:0 0 30px;
    }
    .button {
      padding:12px 30px;
      display:inline-block;
      width:auto;
    }
  }
  .headerWrapper {
    padding:20px 20px 20px;
    height:100%;
    position: relative;
    .touch & {
      overflow-y:scroll;
      @include breakpoint(1025px){
        overflow:initial;
      }
    }
    @include breakpoint(1025px){
      padding:20px 0;
      margin:0 auto;
      width:950px;

    }
    @media screen and (min-width: 1025px) and (min-height:800px) {
      margin-top:150px;
    }
    @media screen and (min-width: 1025px) and (min-height:900px) {
      margin-top:200px;
    }


  }
  .telephone  {
    text-align: right;
    font-family: 'NeoSansStd', sans-serif;
    font-weight:300;
  }
  .office-table {
    font-size:12px;
    @include breakpoint(400px){
      font-size:14px;
    }
    @include breakpoint(1025px){
      font-size:15px;
    }
    .column {
      margin:0 0 10px;

    }
  }
  h2 {
    @include breakpoint(1025px){
      margin-top:0
    }
  }
  h3 {
    font-size: 27px;
    margin: 40px 0 20px;
    color:#fff;
  }
  .social-media {
    text-align:left;
    margin:30px 0 0;
    svg {
      fill:#999;
      display:inline;
      margin:0 10px 0 0;
      width:20px;
      height:20px;
      transition: fill 500ms ease;

    }
    a:hover {
      svg {
        fill:#fff
      }
    }

  }
  .contactForm {
    position: relative;
    fieldset{
      border:none;
      padding:0;
      margin:0 0 0;
    }
  }
  .submitContainer {
    position:relative;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea{
    background:#000;
    background:rgba(0,0,0,0.4);
    border:1px solid #ccc;
    padding:10px;
    color:#ccc;
    font-size:15px;
    width:100%;
    margin:0 0 20px;
    resize: none;
    appearance:none;
    -webkit-appearance:none;
    @include breakpoint(fromMedium){
      margin:0 0 10px;
    }
  }
  select {
    cursor: pointer;

  }
  label {
    position:relative;
  }
  .icon {
    border-left: 1px solid #ccc;
    height: 41px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 53px;
    width: 40px;
    @include breakpoint(fromMedium){
      top:43px;
    }
      svg {
        fill:#fff;
        display:block;
        width:20px;
        height:20px;
      }
    }
  textarea {
    @include breakpoint(1025px){
      height:146px
    }
  }
  .ajax-loader {
    position:absolute;
    right:20px;
    top:14px;
  }
  .screen-reader-response {
    display:none
  }
  .wpcf7-form-control-wrap {
    position:relative;
    -webkit-overflow-scrolling: touch;
    & > * {
      -webkit-transform: translateZ(0px);
    }
  }
  .wpcf7-not-valid-tip {
    background:#ccc;
    background:rgba(204,204,204,0.2);
    font-size:10px;
    padding:2px;
    position:absolute;
    top:0;
    right:0;
    color:#fff;
    &:hover {
      display:none;
    }
  }
  .wpcf7-response-output {
    clear:both;
    padding:20px;
    display:block;
    border:1px solid #fec24d;
    font-size: 14px;
    padding: 10px 15px;
    color:#ff4646;
  }
  .wpcf7-display-none {
    display:none;
  }
  .button {
    width:100%;
    color:#fff;
    background:none;
    border:#7b9c41 2px solid;
    padding:9px;
    font-size:15px;
    max-width: 100%;
    height:auto;
    margin:5px 0 ;
    @include breakpoint(1025px){
        margin:1px 0 ;
    }
  }
  .quickLinks {
    font-size: 24px;
    line-height: 38px
  }
  fieldset{
    @include breakpoint(1025px){
      float:left;
      width:48%;
      &.first {
        margin-right:4%;
      }
    }

  }
}

#closeContactPanel {
    padding: 9px 9px 6px;
    position: absolute;
    right: 10px;;
    top: 10px;
    z-index: 24;
}



