.wpcf7-not-valid {
  border-color:#ff4646 !important;
}
.wpcf7-not-valid-tip {
  display:none !important
}
.wpcf7-response-output  {
  border:none !important
}
.wpcf7-form-control-wrap {
  width:100%;
}
